import { dataset, projectId } from "@/sanity/lib/env";
import { PagePayload } from "@/sanity/types/pagePayload";
import { PortableText } from "@portabletext/react";
import imageUrlBuilder from "@sanity/image-url";
import Image from "next/image";
import { ReactNode } from "react";
import styles from "./SecondaryPageLayout.module.css";

export interface SecondaryPageLayoutProps {
	children?: ReactNode;
	data: PagePayload | null;
}

const builder = imageUrlBuilder({ projectId, dataset });

export const SecondaryPageLayout = ({ children, data }: SecondaryPageLayoutProps) => {
	if (!data) return <>Content not found.</>;

	const { title, mainImage, body } = data ?? {};

	return (
		<div className={styles.content}>
			<article>
				{title ? <h1>{title}</h1> : null}
				{mainImage ? (
					<Image
						src={builder.image(mainImage).width(300).height(300).quality(80).url()}
						width={300}
						height={300}
						alt={mainImage.alt as string}
					/>
				) : null}
				{body ? <PortableText value={body} /> : null}
				{children}
			</article>
		</div>
	);
};

export default SecondaryPageLayout;
