"use client";

import { SecondaryPageLayout } from "@/components/SecondaryPageLayout";
import { pageBySlug } from "@/sanity/lib/queries";
import { useQuery } from "@/sanity/loader/useQuery";
import { PagePayload } from "@/sanity/types/pagePayload";
import { QueryResponseInitial } from "@sanity/react-loader";

export const SecondaryPagePreview = ({
	initial,
	params
}: {
	initial: QueryResponseInitial<PagePayload | null>;
	params: { slug: string };
}) => {
	const { data } = useQuery<PagePayload | null>(pageBySlug, params, {
		initial
	});

	return <SecondaryPageLayout data={data} />;
};

export default SecondaryPagePreview;
