"use client";

import { load, trackPageview } from "fathom-client";
import { usePathname, useSearchParams } from "next/navigation";
import { Suspense, useEffect } from "react";

const FathomTrackingCode = process.env.NEXT_PUBLIC_FATHOM_TRACKING_CODE!;

const TrackPageView = () => {
	const pathname = usePathname();
	const searchParams = useSearchParams();
	useEffect(() => {
		load(FathomTrackingCode, {
			includedDomains: ["kristopherleecargile.com"]
		});
	}, []);

	useEffect(() => {
		trackPageview();
	}, [pathname, searchParams]);

	return null;
};

export const FathomAnalytics = () => {
	return (
		<Suspense fallback={null}>
			<TrackPageView />
		</Suspense>
	);
};

export default FathomAnalytics;
